<!-- eslint-disable no-underscore-dangle -->
<template>
  <div class="player-bar-wrapper clearfix">
    <div class="cta-wrapper float-left" v-if="callToActionEnabled">
      <a
        type="button"
        class="btn btn-success mr-2"
        :href="pageSettings.callToActionLink"
        target="_blank"
        >{{ pageSettings.callToActionText }}</a
      >
    </div>
    <div class="stream-select-wrapper float-right" v-if="hasStreamSelect">
      <b-form-select v-model="selectedStream" @change="updateSelectedStream()">
        <b-form-select-option
          v-for="stream in streamList"
          :value="stream._id"
          :key="stream._id"
          >{{ stream.name }}</b-form-select-option
        >
      </b-form-select>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
/* eslint-disable operator-linebreak */
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  data() {
    return {
      selectedStream: null,
    };
  },
  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
      pageSettings: 'pageSettings/current',
    }),
    callToActionEnabled() {
      if (
        _.has(this.pageSettings, 'callToActionEnabled') &&
        this.pageSettings.callToActionEnabled
      ) {
        return true;
      }
      return false;
    },
    hasStreamSelect() {
      return this.streamList.length >= 2;
    },
  },
  methods: {
    ...mapActions({
      setCurrentStream: 'stream/setCurrent',
    }),
    updateSelectedStream() {
      this.setCurrentStream({ _id: this.selectedStream });
    },
  },
  mounted() {
    this.selectedStream = this.stream._id;
  },
};
</script>

<style lang="scss">
.player-bar-wrapper {
  padding: 8px 16px;
}
</style>
