/* eslint-disable */

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/lang/en';

Vue.use(VueI18n);

const loadedLanguages = ['en'];

export const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages, // set locale messages
});

function setI18nLanguage(lang) {
  i18n.locale = lang;
  return lang;
}

function getMessages(lang) {
  switch (lang) {
    case 'en':
      return import(/* webpackChunkName: "lang-[request]" */ '@/lang/en');
    case 'tr':
      return import(/* webpackChunkName: "lang-[request]" */ '@/lang/tr');
    case 'es':
      return import(/* webpackChunkName: "lang-[request]" */ '@/lang/es');
    case 'de':
      return import(/* webpackChunkName: "lang-[request]" */ '@/lang/de');
    default:
      return import(/* webpackChunkName: "lang-[request]" */ '@/lang/en');
  }
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  return getMessages(lang).then((m) => {
    i18n.setLocaleMessage(lang, m.default[lang]);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  });
}
