/* eslint-disable object-curly-newline */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';

const config = {
  apiKey: 'AIzaSyAznmc7VW5st-6cpLLsDfvlZ_YHmtkr0dI',
  authDomain: 'magnetlive3.firebaseapp.com',
  databaseURL: 'https://magnetlive3-317fd.europe-west1.firebasedatabase.app/',
  projectId: 'magnetlive3',
  storageBucket: 'magnetlive3.appspot.com',
  messagingSenderId: '544101773540',
  appId: '1:544101773540:web:7b6e1d3691032da7c73cf1',
  measurementId: 'G-Z3LFV6RMQQ',
};

firebase.initializeApp(config);

const { auth } = firebase;
const db = firebase.firestore();
const rtdb = firebase.database();
db.settings({ ignoreUndefinedProperties: true });

// lokalde emulatore yönlendir
// if (window.location.hostname === 'localhost') {
//   db.settings({
//     host: 'localhost:1340',
//     ssl: false,
//   });
//   rtdb.useEmulator('localhost', 9003);
//   // auth.useEmulator('http://localhost:5000');
// }

export { auth, db, rtdb, firebase };
