<template>
  <div class="question-wrapper clearfix">
    <div class="form-group">
      <input
        class="form-control"
        id="nameText"
        v-if="showNameArea"
        :placeholder="$t('name')"
        v-model="name"
        style="margin-bottom: 1rem"
        type="text"
      />
      <textarea
        class="form-control"
        id="questionText"
        rows="2"
        v-model="qText"
        :placeholder="$t('type_your_question')"
      ></textarea>
    </div>
    <button
      type="button"
      class="btn btn-primary float-right"
      @click="sendQuestion"
    >
      {{ $t('send') }}
    </button>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      qText: null,
      showNameArea: false,
      name: '',
    };
  },
  mounted() {
    this.showNameArea = this.user.name === '';
  },
  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
    }),
  },
  methods: {
    ...mapActions({
      createQuestionAction: 'question/create',
    }),
    sendQuestion() {
      const name = this.user.name === '' ? this.name : this.user.name;

      this.createQuestionAction({
        data: {
          text: this.qText,
          bucket: 'inbox',
          userName: name,
          location: this.user.location,
          isApproved: false,
        },
      })
        .then(() => {
          this.$bvToast.toast('Your question has been sent', {
            autoHideDelay: 5000,
            appendToast: true,
            noCloseButton: true,
            solid: true,
            variant: 'success',
          });
          this.qText = null;
        })
        .catch((err) => {
          this.$bvToast.toast(err.message, {
            title: 'Error!',
            autoHideDelay: 5000,
            appendToast: true,
            noCloseButton: true,
            solid: true,
            variant: 'danger',
          });
        });
    },
  },
};
</script>

<style lang="scss">
.question-wrapper {
  padding: 0 16px;
}
</style>
