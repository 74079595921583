<template>
  <div id="app" class="app" :style="bgImgStyle">
    <div class="main-content">
      <div
        class="spinner-wrapper d-flex justify-content-center align-items-center"
        v-if="isLoading"
      >
        <div class="loader"></div>
      </div>

      <div class="container" v-if="!isLoading && !hasError">
        <before-event v-if="event.status == 'upcoming'" />
        <after-event v-if="event.status == 'completed'" />
        <live-player v-if="event.status == 'live'" />
        <player-bottom-bar />
        <question-box style="margin-top: 16px" v-if="event.qnaEnabled" />
      </div>
    </div>

    <div class="error-msg-wrapper" v-if="hasError">
      <div class="error-msg">
        <h2>Error!</h2>
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable consistent-return */

import { db } from '@/firebase';
import LivePlayer from '@/components/LivePlayer.vue';
import BeforeEvent from '@/components/BeforeEvent.vue';
import AfterEvent from '@/components/AfterEvent.vue';
import PlayerBottomBar from '@/components/PlayerBottomBar.vue';
import QuestionBox from '@/components/QuestionBox.vue';
import _ from 'lodash';
import { loadLanguageAsync } from '@/utils/i18n-setup';
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    LivePlayer,
    QuestionBox,
    PlayerBottomBar,
    BeforeEvent,
    AfterEvent,
  },
  data() {
    return {
      modalText: {},
      isLoading: true,
      bgImgStyle: {},
      selectedLang: 'en',
      hasError: false,
      errorMessage: null,
    };
  },
  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
      pageSettings: 'pageSettings/current',
      appSettings: 'appsettings/current',
    }),
  },
  methods: {
    ...mapActions({
      setCurrentEvent: 'event/setCurrent',
      setCurrentUser: 'user/setCurrent',
      setStreamList: 'stream/setList',
      setCurrentStream: 'stream/setCurrent',
      setCurrentPageSettings: 'pageSettings/setCurrent',
      getAppSettings: 'appsettings/get',
    }),
    updateSelectedLang() {
      loadLanguageAsync(this.selectedLang);
      this.$moment.locale(this.selectedLang);
    },
    async getData() {
      return Promise.all([
        this.getAppSettings(),
        this.getUser(),
        this.getEvent(),
        this.getStreams(),
        this.getPageSettings(),
      ]);
    },
    async getUser() {
      let randomId = null;

      /*
      if (window.localStorage) {
        if (localStorage.getItem(this.$route.params.slug)) {
          randomId = localStorage.getItem(this.$route.params.slug);
        } else {
          randomId = uuidv4();
        }
      }
      */
      randomId = uuidv4();

      const name = this.$route.query.name || '';
      const uid = this.$route.query.uid || '';
      const user = {
        _id: randomId,
        name,
        uid,
        location: '',
        info: { email: uid, name },
      };

      this.setCurrentUser(user);
      console.log(user);

      localStorage.setItem(this.$route.params.slug, user._id);

      return user;
    },
    async getEvent() {
      return new Promise((resolve, reject) => {
        db.collection('event')
          .doc(this.$route.params.slug)
          .onSnapshot({}, (doc) => {
            if (!doc.exists) {
              reject(new Error('Event Not Found.'));
            }
            console.log('updated');
            console.log(doc.data());
            resolve();
            this.setCurrentEvent(doc.data());
          });
      });
    },
    async getPageSettings() {
      return db
        .collection('event')
        .doc(this.$route.params.slug)
        .collection('settings')
        .doc('pageSettings')
        .get()
        .then((doc) => {
          const pageSettings = doc.data();

          this.setCurrentPageSettings(pageSettings);
        });
    },
    async getStreams() {
      return db
        .collection('event')
        .doc(this.$route.params.slug)
        .collection('stream')
        .get()
        .then((streamList) => {
          const streams = [];

          streamList.forEach((snap) => {
            streams.push(snap.data());
          });

          this.setStreamList(streams);
          this.setCurrentStream(streams[0]);
        });
    },
  },
  mounted() {
    this.getData()
      .then(() => {
        // assign background image
        if (_.has(this.pageSettings, 'bgImgUrl')) {
          this.bgImgStyle = {
            backgroundImage: `url(${this.pageSettings.bgImgUrl})`,
            backgroundPositionX: this.pageSettings.bgImgPosition,
          };
        }

        // init selected language
        loadLanguageAsync(this.event.interfaceLang);
        this.selectedLang = this.event.interfaceLang;
        this.$moment.locale(this.selectedLang);

        this.isLoading = false;
      })
      .catch((err) => {
        this.hasError = true;
        if (err.message === 'Event Not Found.') {
          this.errorMessage = err.message;
        } else {
          this.errorMessage = this.$t('fb_access_error');
        }

        this.isLoading = false;
      });
  },
};
</script>
<style lang="scss">
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: transparent;
}
.container {
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 !important;
}

.spinner-wrapper {
  width: 100vw;
  height: 100vh;
}

.loader,
.loader:after {
  border-radius: 100%;
  width: 64px;
  height: 64px;
}
.loader {
  position: relative;
  border-top: 4px solid rgba(0, 0, 0, 0.1);
  border-right: 4px solid rgba(0, 0, 0, 0.1);
  border-bottom: 4px solid #007bff;
  border-left: 4px solid #007bff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 0.6s infinite linear;
  animation: load8 0.6s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.footer {
  width: 100%;
  background-color: #ececec;
  padding: 0px 16px 1px;
  margin-top: 32px;
  .lang-select-wrapper {
    width: 150px;
  }
  .col-form-label-sm {
    line-height: 0;
    z-index: 100;
    position: relative;
    font-size: 10px;
    left: 13px;
    color: #007bff;
    top: 25px;
  }
  #page-lang {
    padding: 0.975rem 1.75rem 0.275rem 0.75rem;
    height: auto;
  }
}
[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button-bevel !important;
}
.error-msg-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}
.error-msg {
  padding: 32px;
  border: 1px solid #999;
  border-radius: 8px;
  width: calc(100vw - 32px);
  min-width: 280px;
  max-width: 640px;
  text-align: center;
}
</style>
